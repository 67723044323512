import { Typography } from '@mui/material'
import StartEndDate from 'components/AutoForm/Components/StartEndDate'
import GeneratedFormFields, {
  FieldType,
} from 'components/AutoForm/GeneratedFormFields'
import { Flex } from 'uikit/components/Svg/Box'
import FormCard from '../widgets/FormCard'
import { FormProps } from './type'

const Landing: React.FC<FormProps> = ({
  optional,
  onChange,
  register,
  errors,
  control,
}) => {
  return (
    <FormCard
      header="Landing - Latest Update"
      optional={optional}
      onChange={onChange}
    >
      <Flex width="100%" flexDirection="column" paddingTop="10px" rowGap="8px">
        <Typography fontSize="16px">Event Period</Typography>
        <Flex maxWidth="500px">
          <StartEndDate
            control={control}
            errors={errors}
            startTime={{
              name: 'landingBanner.startTime',
              title: 'Publish Start Date & Time',
              isRequired: true,
            }}
          />
        </Flex>
        <Flex height="4px" />
        <GeneratedFormFields
          register={register}
          errors={errors}
          control={control}
          formFields={[
            {
              type: FieldType.STRING,
              title: 'URL',
              name: 'projectUrl',
              isRequired: true,
            },
          ]}
        />

        <Typography fontSize="16px">Upload Image</Typography>
        <Typography fontSize="14px" color="#c1c4c9">
          For mobile and desktop, please upload the same image size: 574*300 px{' '}
        </Typography>
        <GeneratedFormFields
          register={register}
          errors={errors}
          control={control}
          formFields={[
            {
              type: FieldType.IMAGE,
              title: '',
              name: 'imageUrl',
              placeholder: '',
              isRequired: true,
            },
          ]}
        />
      </Flex>
    </FormCard>
  )
}

export default Landing
