import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import loGet from 'lodash/get'
import React, { useEffect, useMemo } from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import useTokens from 'state/token/hooks'
import { fetchTokens } from 'state/token/reducer'
import { useAppDispatch } from 'state/hooks'
import { DefaultValueMap } from '..'

type TokensDropdownProps = {
  name: string
  label?: string
  isRequired?: boolean
  control?: Control<DefaultValueMap, any>
  errors: FieldErrorsImpl<{
    [x: string]: any
  }>
  chainId?: number
  disabled?: boolean
}

const TokensDropdown: React.FC<TokensDropdownProps> = ({
  name,
  control,
  isRequired,
  label,
  errors,
  chainId,
  disabled,
}) => {
  const tokens = useTokens()
  const dispatch = useAppDispatch()

  const tokenList = useMemo(() => {
    if (chainId) {
      return tokens?.filter((token) =>
        token.addresses?.find((address) => address.chainId === chainId),
      )
    } else {
      return tokens
    }
  }, [tokens, chainId])

  useEffect(() => {
    dispatch(fetchTokens())
  }, [dispatch])

  return (
    <Controller
      control={control}
      name={name}
      rules={isRequired ? { required: 'This field is required.' } : {}}
      render={({ field }) => (
        <>
          <FormControl
            variant="filled"
            sx={{ minWidth: 340 }}
            error={!!loGet(errors, name)}
          >
            <InputLabel>{label}</InputLabel>
            <Select
              value={field.value || ''}
              onChange={(evt) => field.onChange(evt.target.value)}
              disabled={disabled}
            >
              {tokenList?.map((token) => (
                <MenuItem key={token.tokenId} value={token.tokenId}>
                  <Box sx={{ display: 'flex' }}>
                    <img
                      width="22px"
                      src={token.tokenImage}
                      alt={token.tokenSymbol}
                    />
                    <Box sx={{ mr: 1 }} />
                    {token.tokenSymbol} ({token.tokenName})
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {loGet(errors, name)?.message as string}
            </FormHelperText>
          </FormControl>
        </>
      )}
    />
  )
}

export default TokensDropdown
