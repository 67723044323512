import { useDispatch, useSelector } from 'react-redux'
import { setDialogState } from 'state/dialog/actions'
import { AppState } from 'state'
import { TDialog } from 'domain/types'
import { initialState } from 'state/dialog/reducer'

const useDialog = () => {
  const dispatch = useDispatch()
  const dialogState = useSelector<AppState, TDialog>(
    (state) => state.dialog.dialogState,
  )

  const showDialog = (params: TDialog) => {
    dispatch(
      setDialogState({
        ...dialogState,
        ...params,
        open: true,
      }),
    )
  }

  const hideDialog = () => {
    dispatch(
      setDialogState({
        ...initialState.dialogState,
      }),
    )
  }

  return { showDialog, hideDialog, dialogState }
}

export default useDialog
