export enum ConnectorNames {
  Injected = 'injected',
}

export interface Config {
  title: string
  connectorId: ConnectorNames
  fathomID?: string
  walletID?: string
}
