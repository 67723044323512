import { useWeb3React } from '@web3-react/core'
import { isWalletConnectedKey } from 'constants/index'
import React from 'react'
import { Navigate } from 'react-router-dom'
import {
  useIsAdmin,
  useIsUserSignedIn,
  useIsUserTotpValidated,
} from 'state/user/hooks'

type Props = {
  roles?: string[]
  children: React.ReactNode
}

const ProtectedRoute: React.FC<Props> = ({ roles, children }) => {
  const { active, account } = useWeb3React()
  const isUserSigned = useIsUserSignedIn()
  const isTotpValidated = useIsUserTotpValidated()
  const isAdmin = useIsAdmin(roles)
  const isConnected = localStorage.getItem(isWalletConnectedKey)

  if ((isConnected && !active) || !account) {
    // TODO: Replace with proper loading screen
    return <div>Loading...</div>
  }

  if (isUserSigned && isAdmin && !isTotpValidated) {
    return <Navigate to="/otp" replace />
  }

  if (!isUserSigned || !isAdmin) {
    return <Navigate to="/login" replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
