import { Sale } from 'domain/types'

const fetchSalesData = async (): Promise<Sale[]> => {
  let sales: Sale[] = []

  const res = await fetch(`/api/backend-service/admin/sales`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  sales = data.data
  return sales
}

const fetchSaleData = async (saleId): Promise<Sale> => {
  const res = await fetch(`/api/backend-service/admin/sales/${saleId}`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  return data.data
}

const downloadWhitelist = (saleId: number) => {
  fetch(`/api/backend-service/admin/whitelist/${saleId}`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
    .then((res) => res.json())
    .then((res) => {
      const data =
        'Address,,Please fill the list of addresses on column A\r\n' +
        res.data.map((value) => value.walletAddress).join('\r\n')

      const blob = new Blob([data], { type: 'text/csv' })

      // to download the file
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `${new Date().toISOString()}_sale_${saleId}_whitelist.csv`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    })
    .catch((error) => {
      console.error(error)
    })
}

const downloadGleamlist = (saleId: number) => {
  fetch(`/api/backend-service/admin/merkletree/${saleId}`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
    .then((res) => res.json())
    .then((res) => {
      const data =
        'Address,allocation (in WEI),,Please fill the list of addresses on column A\r\n' +
        res.data
          .map((value) => `${value.walletAddress},${value.allocation}`)
          .join('\r\n')

      const blob = new Blob([data], { type: 'text/csv' })

      // to download the file
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `${new Date().toISOString()}_sale_${saleId}_whitelist.csv`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    })
    .catch((error) => {
      console.error(error)
    })
}

export { fetchSalesData, fetchSaleData, downloadWhitelist, downloadGleamlist }
