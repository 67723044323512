import { createSlice } from '@reduxjs/toolkit'
import { TAccount } from 'domain/types'
import { RolesMap } from 'views/Account/types'
import { fetchAccountsData } from './fetch'

export interface AccountState {
  accounts: TAccount[]
}

export const initialState: AccountState = {
  accounts: [],
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountsState: (state: AccountState, action) => {
      state.accounts = action.payload
    },
  },
})

export const { setAccountsState } = accountSlice.actions

export const getAccounts = async (): Promise<TAccount[]> => {
  const data = await fetchAccountsData()
  return data
}

export const fetchAccounts = () => async (dispatch: any) => {
  const data = await getAccounts()
  data.forEach((d) => {
    d.role = RolesMap[d.role]
  })
  dispatch(setAccountsState(data))
}

export default accountSlice
