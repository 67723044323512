import React, { useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Chip,
  Grid,
  InputAdornment,
  Modal,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { TAccount } from 'domain/types'
import { useAppDispatch } from 'state/hooks'
import AccountTable from 'views/Account/Table'
import EditAccountModal from './EditAccountModal'
import AddAccountModal from './AddAccountModal'
import AddIcon from '@mui/icons-material/Add'
import useAccount from 'hooks/useAccount'
import useAccounts from 'state/accounts/hooks'
import { fetchAccounts } from 'state/accounts/reducer'
import { Roles } from './types'
import { useWeb3React } from '@web3-react/core'

const RoleOptions = ['Admin', 'Project Partners']

const Main: React.FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const accounts = useAccounts()
  const [searchText, setSearchText] = useState('')
  const [roleFilter, setRoleFilter] = useState<string[]>([])
  const { createAccount, updateAccount, deleteAccount } = useAccount()
  const [openEditAccountModal, setOpenEditAccountModal] = useState(false)
  const [editAccountModalData, setEditAccountModalData] = useState<TAccount>()
  const [openAddAccountModal, setOpenAddAccountModal] = useState(false)

  const handleRowClick = (rowData: TAccount) => {
    setEditAccountModalData(rowData)
    setOpenEditAccountModal(true)
  }

  useEffect(() => {
    dispatch(fetchAccounts())
  }, [dispatch])

  const filteredAccounts = useMemo(() => {
    return accounts.filter((account) => {
      return (
        // system admins are not editable
        account.role !== Roles.SystemAdmin &&
        // filter field name and address
        (account.name.includes(searchText) ||
          account.address.includes(searchText)) &&
        // filter by role
        (roleFilter.length === 0 || roleFilter.includes(account.role))
      )
    })
  }, [accounts, searchText, roleFilter])

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        marginBottom={5}
      >
        <Typography variant="h4">Account Manage</Typography>

        <Button
          variant="contained"
          onClick={() => setOpenAddAccountModal(true)}
        >
          <AddIcon sx={{ paddingRight: '8px', fontSize: '30px' }} />
          <Typography
            color="black"
            fontWeight="500"
            fontSize="14px"
            lineHeight="24px"
          >
            Add Address
          </Typography>
        </Button>
      </Box>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={4}>
          <Autocomplete
            id="search-tags-filled"
            options={[
              ...new Set(
                accounts
                  .map((account) => account.address)
                  .concat(accounts.map((account) => account.name))
                  .filter((value) => value !== ''),
              ).values(),
            ]}
            freeSolo
            onChange={(val) => setSearchText(val.currentTarget.textContent)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Search"
                placeholder="Name or address"
                onChange={(val) => setSearchText(val.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            id="filter-tags-filled"
            options={RoleOptions}
            freeSolo
            renderTags={(values: readonly string[], getTagProps) => {
              return values.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={(event) => {
                    getTagProps({ index }).onDelete(event)
                    setRoleFilter(roleFilter.filter((role) => role !== option))
                  }}
                />
              ))
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Filter"
                placeholder="Select Role"
              />
            )}
            onChange={(e) => {
              if (e.currentTarget?.ariaLabel === 'Clear') {
                setRoleFilter([])
                return
              }
              if (
                !roleFilter.includes(e.currentTarget?.textContent) &&
                e.currentTarget?.textContent !== ''
              ) {
                setRoleFilter(roleFilter.concat(e.currentTarget?.textContent))
              } else {
                setRoleFilter(
                  roleFilter.filter(
                    (role) => role !== e.currentTarget?.textContent,
                  ),
                )
              }
            }}
          />
        </Grid>
      </Grid>
      {filteredAccounts.length === 0 ? (
        <Box
          minHeight={300}
          color={theme.palette.background.paper}
          sx={{
            bgcolor: '#263042',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body1"
            color={theme.palette.text.primary}
            marginBottom={1}
          >
            There is not account yet.
          </Typography>
          <Button
            variant="contained"
            onClick={() => setOpenAddAccountModal(true)}
          >
            CREATE ACCOUNT
          </Button>
        </Box>
      ) : (
        <Box>
          <AccountTable
            onRowClick={handleRowClick}
            user={account}
            data={filteredAccounts as TAccount[]}
          />
        </Box>
      )}

      {/* Modals */}
      <Modal
        open={openEditAccountModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditAccountModal
          data={editAccountModalData}
          onSubmit={updateAccount}
          onClose={() => {
            setOpenEditAccountModal(false)
            dispatch(fetchAccounts())
          }}
          onDelete={deleteAccount}
        />
      </Modal>
      <Modal
        open={openAddAccountModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddAccountModal
          onSubmit={createAccount}
          onClose={() => {
            setOpenAddAccountModal(false)
            dispatch(fetchAccounts())
          }}
        />
      </Modal>
    </>
  )
}

export default Main
