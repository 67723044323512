import { createSlice } from '@reduxjs/toolkit'
import { Chain } from 'domain/types'
import { fetchChainsData, fetchPublicChainsData } from './fetch'

export interface ChainState {
  chains: Chain[]
  publicChains: Chain[]
  isBSC: boolean
}

export const initialState: ChainState = {
  chains: [],
  publicChains: [],
  isBSC: true,
}

export const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    setChainsState: (state: ChainState, action) => {
      state.chains = action.payload
    },
    setPublicChainsState: (state: ChainState, action) => {
      state.publicChains = action.payload
    },
    setIsBSC: (state: ChainState, action) => {
      state.isBSC = action.payload
    },
  },
})

export const { setChainsState, setPublicChainsState } = chainSlice.actions

export const getChains = async (): Promise<Chain[]> => {
  const data = await fetchChainsData()
  return data
}

export const getPublicChains = async (): Promise<Chain[]> => {
  const data = await fetchPublicChainsData()
  return data
}

export const fetchChains = () => async (dispatch: any) => {
  const data = await getChains()
  dispatch(setChainsState(data))
}

export const fetchPublicChains = () => async (dispatch: any) => {
  const data = await getPublicChains()
  dispatch(setPublicChainsState(data))
}

export default chainSlice
