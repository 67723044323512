import { useSelector } from 'react-redux'
import { Chain } from 'domain/types'
import { AppState } from 'state'

export const useChains = (): Chain[] => {
  const data = useSelector((state: AppState) => {
    return state.chain.chains || []
  })
  return data
}

export const usePublicChains = (): Chain[] => {
  const data = useSelector((state: AppState) => {
    return state.chain.publicChains || []
  })
  return data
}

export const useChain = (chainId?: number): Chain => {
  const chains = useChains()

  return chainId && chains.find((chain) => chain.chainID === chainId)
}

export const useIsBSC = (): boolean => {
  return useSelector<AppState, boolean>((state) => {
    return state.chain.isBSC
  })
}

export default useChains
