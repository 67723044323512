import { lazy } from 'react'

import { CssBaseline, ThemeProvider } from '@mui/material'
import useEagerConnect from 'hooks/useEagerConnect'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import {
  Toast,
  ProtectedRoute,
  SuspenseWithChunkError,
  Modal,
  Dialog,
} from 'components'
import theme from './theme'
import ModalProvider from 'components/Modal/ModalContext'
import Account from 'views/Account'
import useInitialSetup from 'hooks/useInitialSetup'
import Marketing from 'views/Marketing'

const Debug = lazy(() => import('./views/Debug'))
// const Home = lazy(() => import('./views/Home'))
const Login = lazy(() => import('./views/Login'))
const VerifyOTP = lazy(() => import('./views/VerifyOTP'))
const NotFound = lazy(() => import('./views/NotFound'))
const Quests = lazy(() => import('./views/Quests'))
const Chains = lazy(() => import('./views/Chains'))
const Projects = lazy(() => import('./views/Projects'))
const Tokens = lazy(() => import('./views/Tokens'))
const Sales = lazy(() => import('./views/Projects/Sales'))

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Projects />
      </ProtectedRoute>
    ),
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'otp',
    element: <VerifyOTP />,
  },

  {
    path: 'debug',
    element: <Debug />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'quests/*',
    element: (
      <ProtectedRoute>
        <Quests />
      </ProtectedRoute>
    ),
  },
  {
    path: 'chains/*',
    element: (
      <ProtectedRoute>
        <Chains />
      </ProtectedRoute>
    ),
  },
  {
    path: 'tokens/*',
    element: (
      <ProtectedRoute>
        <Tokens />
      </ProtectedRoute>
    ),
  },
  {
    path: 'account-manage/*',
    element: (
      <ProtectedRoute roles={['SYSTEM_ADMIN']}>
        <Account />
      </ProtectedRoute>
    ),
  },
  {
    path: 'marketing/*',
    element: (
      <ProtectedRoute>
        <Marketing />
      </ProtectedRoute>
    ),
  },
  {
    path: 'projects/*',
    element: (
      <ProtectedRoute>
        <Projects />
      </ProtectedRoute>
    ),
  },
  {
    path: 'sales/*',
    element: (
      <ProtectedRoute>
        <Sales />
      </ProtectedRoute>
    ),
  },
])

const App: React.FC = () => {
  useEagerConnect()
  useInitialSetup()

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <CssBaseline />
        <SuspenseWithChunkError fallback={<div />}>
          <Toast />
          <Modal />
          <Dialog />
          <RouterProvider router={router} />
        </SuspenseWithChunkError>
      </ModalProvider>
    </ThemeProvider>
  )
}

export default App
