import { createReducer } from '@reduxjs/toolkit'
import { setDialogState } from './actions'
import { TDialog } from 'domain/types'

export interface DialogState {
  dialogState: TDialog
}

export const initialState: DialogState = {
  dialogState: {
    open: false,
    title: '',
    content: '',
    confirmButtonLabel: 'CONFIRM',
    cancelButtonLabel: 'CANCEL',
    icon: null,
    style: {},
    variant: 'default',
  },
}

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(setDialogState, (state, { payload }) => {
    state.dialogState = { ...state.dialogState, ...payload }
  })
})

export default userReducer
