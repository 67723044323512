import React from 'react'
import styled from '@emotion/styled'

interface Props {
  children: React.ReactNode
}

const StyledPage = styled.div`
  background-color: #263042;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
`

const Page: React.FC<Props> = ({ children, ...props }) => {
  return (
    <>
      <StyledPage {...props}>{children}</StyledPage>
    </>
  )
}

export default Page
