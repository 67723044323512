import { Typography } from '@mui/material'
import GeneratedFormFields, {
  FieldType,
} from 'components/AutoForm/GeneratedFormFields'
import { Flex } from 'uikit/components/Svg/Box'
import FormCard from '../widgets/FormCard'
import { FormProps } from './type'

const ExploreKeyBannerWithTimer: React.FC<FormProps> = ({
  optional,
  onChange,
  register,
  errors,
  control,
}) => {
  return (
    <FormCard
      header="Explore - Key banner with timer"
      optional={optional}
      onChange={onChange}
    >
      <Flex width="100%" flexDirection="column" paddingTop="10px" rowGap="8px">
        <Typography fontSize="16px">Event Period</Typography>
        <Typography fontSize="14px" color="#c1c4c9">
          This banner will be shown 2 days before the staking starts for the IDO
          Sale type of promotion.
        </Typography>
        <GeneratedFormFields
          register={register}
          errors={errors}
          control={control}
          formFields={[
            {
              type: FieldType.STRING,
              title: 'Promotion Type',
              name: 'promotion',
              isRequired: true,
            },
            {
              type: FieldType.STRING,
              title: 'Sale/Event ID',
              name: 'id',
              isRequired: true,
            },
            {
              type: FieldType.STRING,
              title: 'URL',
              name: 'url',
              isRequired: true,
            },
          ]}
        />

        <Typography fontSize="16px">Upload Image</Typography>
        <Typography fontSize="14px" color="#c1c4c9">
          For mobile and desktop, please upload the same image size: 574*300 px
        </Typography>
        <GeneratedFormFields
          register={register}
          errors={errors}
          control={control}
          formFields={[
            {
              type: FieldType.IMAGE,
              title: '',
              name: 'explorerTimerImage',
              placeholder: '',
              isRequired: true,
            },
          ]}
        />
      </Flex>
    </FormCard>
  )
}

export default ExploreKeyBannerWithTimer
