import { styled, Typography } from '@mui/material'
import React from 'react'
import { Flex } from 'uikit/components/Svg/Box'
import InputForm from './InputForm'
import { Roles } from './types'

const StyledFlex = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #263042;
  border: 2px solid #000;
  padding: 4px;
`

interface Props {
  onSubmit: (data: any) => void
  onClose: () => void
}

const AddAccountModal: React.FC<Props> = ({ onSubmit, onClose }) => {
  return (
    <StyledFlex>
      <Flex
        alignContent="center"
        alignItems="center"
        margin="auto"
        flexDirection="column"
        padding="44px 24px 24px"
      >
        <Typography paddingBottom="32px" variant="h5">
          Create Account Role
        </Typography>
        <InputForm
          onClose={onClose}
          onSubmit={onSubmit}
          defaultValues={{
            id: null,
            address: '',
            name: '',
            role: Roles.InternalAdmin,
          }}
          noDuplicateAddress
        />
      </Flex>
    </StyledFlex>
  )
}

export default AddAccountModal
