import { useCallback, useState } from 'react'
import useToast from './useToast'

export const useBanner = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { showToast } = useToast()

  const createBanner = useCallback(
    (body: any) => {
      setIsLoading(true)
      fetch(`/api/backend-service/admin/banners`, {
        credentials: 'include',
        headers: [['Cache-Control', 'no-cache']],
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          }
        })
        .then(() => {
          showToast({
            message: `banner is successfully created`,
            type: 'success',
          })
        })
        .catch((error) => {
          showToast({
            message: `Error occurred: ${error}`,
            type: 'error',
          })
          setIsLoading(false)
        })
    },
    [showToast],
  )

  const updateBanner = useCallback(
    (body: any, toast?: string) => {
      setIsLoading(true)
      fetch(`/api/backend-service/admin/banners/${body.id}`, {
        credentials: 'include',
        headers: [['Cache-Control', 'no-cache']],
        method: 'PUT',
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          }
        })
        .then(() => {
          showToast({
            message: toast || `Banner is successfully updated`,
            type: 'success',
          })
        })
        .catch((error) => {
          showToast({
            message: `Error occurred: ${error}`,
            type: 'error',
          })
          setIsLoading(false)
        })
    },
    [showToast],
  )

  const deleteBanner = useCallback(
    (id: string) => {
      setIsLoading(true)
      const bannerBody = getBanner(id)
      bannerBody.then((res) => {
        updateBanner(
          { ...res, isActive: false },
          'Banner is successfully deleted',
        )
      })
    },
    [updateBanner],
  )

  const getBanner = (id: string): Promise<any> => {
    return fetch(`/api/backend-service/admin/banners/${id}`, {
      credentials: 'include',
      headers: [['Cache-Control', 'no-cache']],
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status_code !== 200) {
          throw res.error
        }
        return res.data
      })
      .catch((error) => {
        console.log(error)
        return {}
      })
  }

  return { createBanner, updateBanner, deleteBanner, getBanner, isLoading }
}

export default useBanner
