import { createSlice } from '@reduxjs/toolkit'
import { Token } from 'domain/types'
import { fetchTokensData } from './fetch'

export interface TokenState {
  tokens: Token[]
}

export const initialState: TokenState = {
  tokens: [],
}

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setTokensState: (state: TokenState, action) => {
      state.tokens = action.payload
    },
  },
})

export const { setTokensState } = tokenSlice.actions

export const getTokens = async (): Promise<Token[]> => {
  const data = await fetchTokensData()
  return data
}

export const fetchTokens = () => async (dispatch: any) => {
  const data = await getTokens()
  dispatch(setTokensState(data))
}

export default tokenSlice
