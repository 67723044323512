import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Button, Link, Typography } from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import StarIcon from '@mui/icons-material/Star'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'state/hooks'
import { fetchProjects } from 'state/projects/reducer'
import { Flex } from 'uikit/components/Svg/Box'
import Progress from './widgets/Progress'
import MasterForm from './forms/MasterForm'
import useBanner from 'hooks/useBanner'
import { ALL_FORMS } from './forms/type'

const Create: React.FC = () => {
  const dispatch = useAppDispatch()
  const { createBanner } = useBanner()
  const navigate = useNavigate()

  const onFormChanged = (index: number) => {
    return (filled: boolean) => {
      const newFormFilled = [...formFilled]
      newFormFilled[index] = filled
      setFormFilled(newFormFilled)
    }
  }

  const [fieldStates, setFieldStates] = useState({})

  const handleFieldStates = (key?: string) => {
    return (data: any) => {
      let newFieldStates = {}
      if (key) {
        newFieldStates = fieldStates
        newFieldStates[key] = data
      } else {
        newFieldStates = { ...fieldStates, ...data }
      }
      setFieldStates(newFieldStates)
    }
  }

  // don't validate until user first clicks save
  const [shouldValidate, setShouldValidate] = useState(0)

  const { state } = useLocation()
  const { defaultValues } = state || {}

  const [formFilled, setFormFilled] = useState(
    ALL_FORMS.map((form) => {
      // the form is filled if it is optional
      return (
        form.optional ||
        (form.key ? !!defaultValues?.[form.key] : defaultValues)
      )
    }),
  )

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Title */}
        <Box>
          <Breadcrumbs maxItems={3} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              href="/"
              title="home"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.primary"
            >
              <StarIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Marketing Promotion
            </Typography>
          </Breadcrumbs>
          <Box mb={5}>
            <Typography variant="h4">Promotion Info</Typography>
          </Box>
        </Box>

        {/* Save and Publish Buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => {
              setShouldValidate(shouldValidate + 1)
              // call create banner if all forms are filled
              if (!formFilled.includes(false)) {
                createBanner(fieldStates)
                navigate(`/marketing`)
              }
            }}
          >
            <Typography
              color="black"
              fontWeight="500"
              fontSize="14px"
              lineHeight="24px"
            >
              Save
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            disabled={formFilled.includes(false)}
            onClick={() => {
              // update banner to published
              createBanner(fieldStates)
              navigate(`/marketing`)
            }}
          >
            <Typography
              color="black"
              fontWeight="500"
              fontSize="14px"
              lineHeight="24px"
            >
              Publish
            </Typography>
          </Button>
        </Box>

        {/* Form */}
      </Box>
      <Flex justifyContent="space-between">
        {/* Side Bar -- Progress */}
        <Flex>
          <Flex
            flexDirection="column"
            minWidth="170px"
            paddingRight="40px"
            rowGap="12px"
          >
            <Progress checked={formFilled[0]} text="Banner Info" />
            <Progress checked={formFilled[1]} text="Landing - Latest updates" />
            <Progress
              checked={formFilled[2]}
              text="Explorer - Key banner with timer"
            />
            <Progress checked={formFilled[3]} text="Explorer - key banner" />
            <Progress
              checked={formFilled[4]}
              text="Account Sidebar - Promotion"
            />
          </Flex>
        </Flex>
        {/* Content -- Form cards */}
        <Flex flexDirection="column" width="100%">
          {ALL_FORMS.map((form, idx) => {
            return (
              <MasterForm
                formType={form.formType}
                optional={form.optional}
                onChange={onFormChanged(idx)}
                onFieldChanged={handleFieldStates(form.key)}
                shouldValidate={shouldValidate}
                defaultValues={defaultValues}
              />
            )
          })}
        </Flex>
      </Flex>
    </>
  )
}

export default Create
