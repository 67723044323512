import { useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'
import { fetchPublicChains } from 'state/chain/reducer'
import { clearUnavailable } from 'state/app'

const useInitialSetup = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(clearUnavailable())
    dispatch(fetchPublicChains())
  }, [dispatch])
}

export default useInitialSetup
