import React, { useCallback, useState } from 'react'
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Table,
  Typography,
} from '@mui/material'

import { TPromotion } from 'domain/types'
import Flex from 'uikit/components/Svg/Box/Flex'
import TableMenu from './TableMenu'

type TColumnId = 'id' | 'bannerTitle' | 'latestUpdate' | 'lastEditor'

interface Column {
  id: TColumnId
  label: string
  minWidth?: number
  maxWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'id', label: 'ID' },
  { id: 'bannerTitle', label: 'Banner Title' },
  { id: 'latestUpdate', label: 'Latest Update' },
  { id: 'lastEditor', label: 'Last Editor' },
]

interface Props {
  data: TPromotion[]
  onRowClickEdit: (rowData: TPromotion) => void
  onRowClickDelete: (rowData: TPromotion) => void
}

const PromotionTable: React.FC<Props> = ({
  data = [],
  onRowClickEdit,
  onRowClickDelete,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const renderTableCell = useCallback((id: TColumnId, value: any, row: any) => {
    switch (id) {
      case 'bannerTitle':
        return (
          <Flex>
            <Flex
              paddingRight="5px"
              alignSelf="center"
              style={{
                width: 32,
                height: 32,
                minWidth: 32,
                backgroundColor: '#282E3A',
                marginRight: '15px',
                alignSelf: 'center',
                borderRadius: '50%',
              }}
            >
              <img style={{ height: '100' }} src={row.projectImage} alt="" />
            </Flex>
            <Typography alignSelf="center">{value}</Typography>
          </Flex>
        )
      case 'lastEditor':
        // TODO: fetch and show editor alias
        return <Typography>{value}</Typography>
      default:
        return <Typography>{value}</Typography>
    }
  }, [])

  return (
    <Paper
      sx={{ width: '100%', overflow: 'hidden', backgroundColor: '#263042' }}
    >
      <TableContainer sx={{ maxHeight: 720 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ '& th': { backgroundColor: '#263042' } }}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id] || ''
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {renderTableCell(column.id, value, row)}
                        </TableCell>
                      )
                    })}
                    <TableMenu
                      onRowClickEdit={onRowClickEdit}
                      onRowClickDelete={onRowClickDelete}
                      row={row}
                    />
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default PromotionTable
