import { TPromotion } from 'domain/types'

const fetchPromotionsData = async (): Promise<TPromotion[]> => {
  let promotions: TPromotion[] = []

  const res = await fetch(`/api/backend-service/admin/banners`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  promotions = data.data
  return promotions
}

export { fetchPromotionsData }
