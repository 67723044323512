import React from 'react'
import { TableCell, IconButton, Menu, MenuItem } from '@mui/material'
import { TPromotion } from 'domain/types'

import EditIcon from '@mui/icons-material/Edit'

interface Props {
  onRowClickDelete: (rowData: TPromotion) => void
  onRowClickEdit: (rowData: TPromotion) => void
  row: TPromotion
}

const TableMenu: React.FC<Props> = ({
  onRowClickEdit,
  onRowClickDelete,
  row,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <TableCell>
      <IconButton
        aria-label="edit"
        color="primary"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleEditClick}
      >
        <EditIcon />
      </IconButton>
      <Menu
        id="banner-table-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => onRowClickEdit(row)}>Edit</MenuItem>
        <MenuItem onClick={() => onRowClickDelete(row)}>Delete</MenuItem>
      </Menu>
    </TableCell>
  )
}

export default TableMenu
