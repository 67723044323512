import React, { useCallback, useState } from 'react'
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Table,
  IconButton,
  Typography,
  Chip,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import { TAccount } from 'domain/types'
import Icon from './Icon'
import Flex from 'uikit/components/Svg/Box/Flex'

type TColumnId = 'address' | 'name' | 'role'

interface Column {
  id: TColumnId
  label: string
  minWidth?: number
  maxWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'address', label: 'Address' },
  { id: 'name', label: 'Name' },
  {
    id: 'role',
    label: 'Role',
  },
]

interface Props {
  data: TAccount[]
  user: string
  onRowClick: (rowData: TAccount) => void
}

const AccountTable: React.FC<Props> = ({ data = [], user, onRowClick }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const renderTableCell = useCallback(
    (id: TColumnId, value: any, user: string) => {
      switch (id) {
        case 'role':
          return (
            <Flex>
              <Flex paddingRight="5px" alignSelf="center">
                <Icon role={value} size="32px" backgroundColor="#282E3A" />
              </Flex>
              <Typography alignSelf="center">{value}</Typography>
            </Flex>
          )
        default:
          if (value === user) {
            return (
              <Flex>
                <Typography lineHeight={2} paddingRight={'25px'}>
                  {value}
                </Typography>
                <Chip variant="filled" color="success" label="You" />
              </Flex>
            )
          }
          return <Typography>{value}</Typography>
      }
    },
    [],
  )

  return (
    <Paper
      sx={{ width: '100%', overflow: 'hidden', backgroundColor: '#263042' }}
    >
      <TableContainer sx={{ maxHeight: 720 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ '& th': { backgroundColor: '#263042' } }}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id] || ''
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {renderTableCell(column.id, value, user)}
                        </TableCell>
                      )
                    })}
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={() => onRowClick(row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default AccountTable
