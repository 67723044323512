import { Box } from '@mui/material'

interface Props {
  children?: React.ReactNode
  style?: React.CSSProperties
}

const ModalBody: React.FC<Props> = ({ children, style }) => {
  return <Box sx={style}>{children}</Box>
}

export default ModalBody
