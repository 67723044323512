import { Project } from 'domain/types'

const fetchProjectsData = async (): Promise<Project[]> => {
  let projects: Project[] = []

  const res = await fetch(`/api/backend-service/admin/projects`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  projects = data.data
  return projects
}

export { fetchProjectsData }
