import { useSelector } from 'react-redux'
import { Project } from 'domain/types'
import { AppState } from 'state'

export const useProjects = (): Project[] => {
  const data = useSelector((state: AppState) => {
    return state.project.projects || []
  })
  return data
}

export const useProject = (projectId?: number): Project => {
  const projects = useProjects()

  return (
    projectId && projects.find((project) => project.projectId === projectId)
  )
}
export default useProjects
