import { createSlice } from '@reduxjs/toolkit'
import { Totp } from 'domain/types'
import { fetchTOTPStatus } from './fetch'

export interface TOTPState {
  otp: Totp
}

export const initialState: TOTPState = {
  otp: {
    state: '',
    qrCode: '',
  },
}

export const totpSlice = createSlice({
  name: 'totp',
  initialState,
  reducers: {
    setTOTPState: (state: TOTPState, action) => {
      state.otp = action.payload
    },
  },
})

export const { setTOTPState } = totpSlice.actions

export const getData = async (): Promise<Totp> => {
  const data = await fetchTOTPStatus()
  return data
}

export const fetchTOTPStatusData = () => async (dispatch: any) => {
  const data = await getData()
  dispatch(setTOTPState(data))
}

export default totpSlice
