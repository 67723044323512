import React, { useState, forwardRef, useMemo, useEffect } from 'react'
import {
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import CloseIcon from '@mui/icons-material/Close'

import { useDropzone, FileWithPath } from 'react-dropzone'
import { Noop } from 'react-hook-form'
import useFileUploader from 'hooks/useFileUploader'
import useFormat from 'hooks/useFormat'

export type DropZoneFile = {
  path?: string
  preview?: string
  lastModified?: number
  lastModifiedDate?: Date
  name?: string
  size?: number
  type?: string
  webkitRelativePath?: string
}

export enum FileType {
  image = 'image',
  csv = 'csv',
}

const acceptTypes = {
  [FileType.image]: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/svg': ['.svg'],
  },
  [FileType.csv]: {
    'file/csv': ['.csv'],
  },
}
interface Props {
  onChange?: (...event: any[]) => void
  onBlur?: Noop
  value?: string | undefined
  name?: string
  helperText?: string
  context?: string
  style?: React.CSSProperties
  hasError?: boolean
  fileType?: FileType
  defaultValue?: string
  requiredDimensions?: { width: number; height: number }[]
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const FileUpload: React.FC<Props> = forwardRef((props, ref) => {
  const {
    value: imgUrl,
    onChange,
    helperText,
    context,
    style: styleProps,
    hasError = false,
    fileType = FileType.image,
    defaultValue,
    requiredDimensions,
  } = props

  const [files, setFiles] = useState<any>([])
  const theme = useTheme()
  const { uploadFile } = useFileUploader()
  const { formatBytes } = useFormat()
  const [dimensionError, setDimensionError] = useState<string | null>(null)

  const onDropAccepted = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (fileType === FileType.image) {
      const image = new Image()
      image.src = URL.createObjectURL(file)
      image.onload = () => {
        const validDimensions = requiredDimensions?.some(
          (d) => image.width === d.width && image.height === d.height,
        )

        if (validDimensions || validDimensions === undefined) {
          setDimensionError(null)
          const reader = new FileReader()
          reader.onload = () => {
            setFiles(
              acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                  rawFile: reader.result,
                }),
              ),
            )
          }
          reader.readAsBinaryString(file)
        } else {
          setDimensionError(
            validDimensions === undefined
              ? 'Upload failed please try again'
              : `Upload failed. Please upload the image size:${requiredDimensions?.map(
                  (d) => `${d.width}x${d.height}px, PNG, JPG file`,
                )}`,
          )
          setFiles([])
        }
        URL.revokeObjectURL(image.src)
      }
      image.onerror = () => {
        setDimensionError('You cannot leave this field empty')
      }
    } else if (fileType === FileType.csv) {
      // Handle CSV files here
      const reader = new FileReader()
      reader.onload = () => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              rawFile: reader.result,
              preview: URL.createObjectURL(file),
            }),
          ),
        )
      }
      reader.readAsBinaryString(file)
    }
  }

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptTypes[fileType],
    maxFiles: 1,
    onDropRejected: async (files) => {
      console.log('REJECTED: ', files)
    },
    onDropAccepted,
  })
  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  const isImage = fileType === FileType.image

  const FileItems = useMemo(
    () => (
      <List>
        {acceptedFiles.map((file: FileWithPath) => (
          <ListItem
            key={file.path}
            disablePadding
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <ListItemText>{file.path}</ListItemText>
            <ListItemText>{formatBytes(file.size)} • Complete</ListItemText>
          </ListItem>
        ))}
      </List>
    ),
    [acceptedFiles, formatBytes],
  )

  useEffect(() => {
    if (files.length > 0) {
      if (files[0].path?.length > 0) {
        uploadFile(
          {
            file: files[0] as Blob,
            path: files[0]?.path || '',
          },
          (res: { url: string }) => {
            onChange && onChange(res.url)
          },
        )
      }
      if (isImage) {
        onChange?.(files[0].preview)
      } else {
        onChange?.(files[0].rawFile)
      }
    } else {
      onChange(undefined)
    }
  }, [files, uploadFile, isImage, onChange])

  useEffect(() => {
    if (imgUrl && isImage) {
      setFiles([
        {
          preview: imgUrl,
        },
      ])
    }
  }, [imgUrl, isImage])

  return (
    <Box>
      <Box>
        <Typography color="text.secondary">{helperText}</Typography>
      </Box>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 4,
          cursor: 'pointer',
          border: hasError
            ? '1px solid #E57373'
            : '1px dashed rgba(255, 255, 255, 0.12)',
          background: hasError ? 'rgba(244, 67, 54, 0.08)' : '#1D2634',
          borderRadius: 1,
          ...style,
        }}
        {...getRootProps()}
        style={styleProps}
      >
        {files[0] && isImage ? (
          <Box
            sx={{
              width: 96,
              height: 67,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={files[0]?.preview}
              alt="file"
              style={{
                display: 'inline-flex',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                boxSizing: 'border-box',
              }}
            />
          </Box>
        ) : null}
        {files.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <Box
              sx={{
                mb: 2,
                color: hasError ? '#E57373' : '#91B6FF',
                padding: 1,
                width: 40,
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: hasError
                  ? 'rgba(244, 67, 54, 0.08)'
                  : 'rgba(144, 202, 249, 0.12)',
                borderRadius: '64px',
              }}
            >
              <UploadFileIcon />
            </Box>

            <input {...getInputProps()} />
            <Typography
              variant="body1"
              color={theme.palette.text.primary}
              mb={1}
            >
              <span style={{ color: '#91B6FF', textDecoration: 'underline' }}>
                Click to upload{' '}
              </span>
              or drag and drop
            </Typography>
            <Typography variant="body2" color="grey" fontSize={16}>
              {defaultValue && dimensionError === null ? (
                defaultValue
              ) : (
                <Typography color="#E57373">{dimensionError}</Typography>
              )}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: hasError ? '#E57373' : theme.palette.text.secondary,
              }}
            >
              {hasError ? `You cannot leave this field empty.` : context}
            </Typography>
            {/* <Box>
              {dimensionError && (
                
              )}
            </Box> */}
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                flex: 1,
                px: 3,
              }}
            >
              <input style={{ flex: 1 }} {...getInputProps()} />
              {FileItems}
              <Box sx={{ width: '100%' }} flex={4}>
                {acceptedFiles.length === 0 &&
                  files.length > 0 &&
                  files[0].preview?.length > 0 && (
                    <Box marginBottom={1}>
                      {files[0].preview.match(/\/([^/]+)\/?$/)?.length > 1
                        ? files[0].preview.match(/\/([^/]+)\/?$/)[1]
                        : ''}
                    </Box>
                  )}
                <LinearProgress
                  variant="determinate"
                  value={files[0].preview ? 100 : 0}
                />
              </Box>
            </Box>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                setFiles([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
      </Paper>
    </Box>
  )
})

export default FileUpload
