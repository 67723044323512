import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import loGet from 'lodash/get'
import React, { useEffect } from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import useProjects from 'state/projects/hooks'
import { fetchProjects } from 'state/projects/reducer'
import { useAppDispatch } from 'state/hooks'
import { DefaultValueMap } from '..'

type ProjectsDropdownProps = {
  name: string
  label?: string
  isRequired?: boolean
  control?: Control<DefaultValueMap, any>
  errors: FieldErrorsImpl<{
    [x: string]: any
  }>
  disabled?: boolean
  useNameAsValue?: boolean
}

const ProjectsDropdown: React.FC<ProjectsDropdownProps> = ({
  name,
  control,
  isRequired,
  label,
  errors,
  disabled,
  useNameAsValue,
}) => {
  const projects = useProjects()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  return (
    <Controller
      control={control}
      name={name}
      rules={isRequired ? { required: 'This field is required.' } : {}}
      render={({ field }) => (
        <>
          <FormControl
            variant="filled"
            sx={{ minWidth: 340 }}
            error={!!loGet(errors, name)}
          >
            <InputLabel>{label}</InputLabel>
            <Select
              value={field.value || ''}
              onChange={(evt) => field.onChange(evt.target.value)}
              disabled={disabled}
            >
              {projects.map((project) => (
                <MenuItem
                  key={project.projectId}
                  value={useNameAsValue ? project.title : project.projectId}
                >
                  <Box sx={{ display: 'flex' }}>
                    <img
                      width="22px"
                      src={project.projectImage}
                      alt={project.symbol}
                    />
                    <Box sx={{ mr: 1 }} />
                    {project.symbol} ({project.title})
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {loGet(errors, name)?.message as string}
            </FormHelperText>
          </FormControl>
        </>
      )}
    />
  )
}

export default ProjectsDropdown
