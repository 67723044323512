import { ConnectorNames } from 'constants/types'

const getProvider = () => {
  const { connectorId } = window.localStorage

  if (!connectorId) {
    return null
  }
  switch (connectorId) {
    case ConnectorNames.Injected:
      return (window as any).ethereum
    default:
      return null
  }
}

export default getProvider
