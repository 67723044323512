import React from 'react'
import KeyIcon from '@mui/icons-material/Key'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import { Flex } from 'uikit/components/Svg/Box'
import { Roles } from './types'

interface Props {
  role: Roles
  size: string
  backgroundColor: string
}

const Icon: React.FC<Props> = ({ role, size, backgroundColor }) => {
  const renderIcon = () => {
    switch (role) {
      case Roles.InternalAdmin:
        return <KeyIcon fontSize="small" />
      case Roles.ProjectPartners:
        return <SupervisorAccountIcon fontSize="small" />
    }
  }

  return (
    <Flex
      height={size}
      width={size}
      borderRadius="50%"
      bgcolor={backgroundColor}
      justifyContent="center"
    >
      <Flex alignSelf="center">{renderIcon()}</Flex>
    </Flex>
  )
}

export default Icon
