import { TAccount } from 'domain/types'

const fetchAccountsData = async (): Promise<TAccount[]> => {
  let accounts: TAccount[] = []

  const res = await fetch(`/api/backend-service/admin/account`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  accounts = data.data
  return accounts
}

export { fetchAccountsData }
