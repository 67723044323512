import React from 'react'
import { Box } from '@mui/material'
import Navigation from 'components/Navigation'

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Navigation />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          background: '#1D2634',
          maxWidth: 'calc(100vw - 256px);',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Layout
