import { Box, FormControl, FormHelperText, Switch } from '@mui/material'
import loGet from 'lodash/get'
import React, { useEffect } from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import { useAppDispatch } from 'state/hooks'
import { fetchChains } from 'state/chain/reducer'
import { DefaultValueMap } from '..'

type DropdownProps = {
  name: string
  label?: string
  isRequired?: boolean
  disabled?: boolean
  control?: Control<DefaultValueMap, any>
  errors: FieldErrorsImpl<{
    [x: string]: any
  }>
  minWidth?: string
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  control,
  isRequired,
  label,
  errors,
  minWidth,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchChains())
  }, [dispatch])

  return (
    <Controller
      control={control}
      name={name}
      rules={isRequired ? { required: 'This field is required.' } : {}}
      render={({ field }) => (
        <>
          <FormControl
            variant="filled"
            sx={{ minWidth: minWidth || 340 }}
            error={!!loGet(errors, name)}
          >
            <Box style={{ marginBottom: 4, color: 'rgba(255, 255, 255, 0.7)' }}>
              {label}
            </Box>
            <Switch
              value={field.value}
              checked={field.value}
              onChange={(_, val) => {
                console.log(val, field, 'wuwuuw')
                field.onChange(val)
              }}
            />
            <FormHelperText>
              {loGet(errors, name)?.message as string}
            </FormHelperText>
          </FormControl>
        </>
      )}
    />
  )
}

export default Dropdown
