import { Checkbox, Typography } from '@mui/material'
import { Flex } from 'uikit/components/Svg/Box'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CircleCheckedFilled from '@mui/icons-material/CheckCircle'

interface Props {
  checked: boolean
  text: string
}

const Progress: React.FC<Props> = ({ checked, text }) => {
  return (
    <Flex flexDirection="row">
      <Checkbox
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<CircleCheckedFilled />}
        checked={checked}
        color="success"
      />
      <Typography alignSelf="center">{text}</Typography>
    </Flex>
  )
}

export default Progress
