import StarIcon from '@mui/icons-material/Star'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import FolderIcon from '@mui/icons-material/Folder'
import LayersIcon from '@mui/icons-material/Layers'
import InboxIcon from '@mui/icons-material/Inbox'
import { ReactNode } from 'react'

type NavigationConfig = {
  icon?: ReactNode
  title: string
  children?: NavigationConfig[]
  link?: string
}

const config = (isSystemAdmin: boolean): NavigationConfig[] => {
  return [
    {
      icon: <InboxIcon />,
      title: 'Listings',
      children: [
        { title: 'Chains', link: '/chains' },
        { title: 'Tokens', link: '/tokens' },
      ],
    },
    {
      icon: <FolderIcon />,
      title: 'Projects',
      link: '/projects',
    },
    {
      icon: <LayersIcon />,
      title: 'Program',
      children: [{ title: 'Impossible Quests', link: '/quests' }],
    },
    {
      icon: <StarIcon />,
      title: 'Marketing',
      link: '/marketing',
    },
    isSystemAdmin && {
      icon: <AccountCircleIcon />,
      title: 'Account Manage',
      link: '/account-manage',
    },
  ]
}

export default config
