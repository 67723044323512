import { createSlice } from '@reduxjs/toolkit'
import { Project } from 'domain/types'
import { fetchProjectsData } from './fetch'

export interface ProjectState {
  projects: Project[]
}

export const initialState: ProjectState = {
  projects: [],
}

export const chainSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectsState: (state: ProjectState, action) => {
      state.projects = action.payload
    },
  },
})

export const { setProjectsState } = chainSlice.actions

export const getProjects = async (): Promise<Project[]> => {
  const data = await fetchProjectsData()
  return data
}

export const fetchProjects = () => async (dispatch: any) => {
  const data = await fetchProjectsData()
  dispatch(setProjectsState(data))
}

export default chainSlice
