import { useDispatch, useSelector } from 'react-redux'
import { setToastState } from 'state/toast/actions'
import { AppState } from 'state'
import { TToast } from 'domain/types'

const useToast = () => {
  const dispatch = useDispatch()
  const toastState = useSelector<AppState, TToast>(
    (state) => state.toast.toastState,
  )

  const showToast = (params: TToast) => {
    dispatch(
      setToastState({
        ...toastState,
        ...params,
        open: true,
      }),
    )
  }

  const hideToast = () => {
    dispatch(
      setToastState({
        ...toastState,
        open: false,
      }),
    )
  }

  return { showToast, hideToast, toastState }
}

export default useToast
