import styled from '@emotion/styled'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import { useState } from 'react'
import { Flex } from 'uikit/components/Svg/Box'

interface Props {
  header: string
  optional?: boolean
  onChange?: (value: boolean) => void
  children?: React.ReactNode
}

const StyledFormCard = styled(Flex)`
  background-color: #313a4b;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 16px;
  flex-direction: column;
  padding: 16px;
`

const ContentContainer = styled(Flex)`
  padding: 16px;
  width: 100%;
`

const FormCard: React.FC<Props> = ({
  header,
  optional,
  onChange,
  children,
}) => {
  // not to open the card if it is optional
  const [open, setOpen] = useState(!optional)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // checked means it's filled or skipped
    onChange(event.target.checked)
    // collapse the form if it is unchecked (skipped)
    setOpen(event.target.checked)
  }

  const label = (
    <Typography
      color="white"
      fontWeight="500"
      fontSize="20px"
      lineHeight="32px"
    >
      {header}
    </Typography>
  )
  return (
    <StyledFormCard>
      {optional ? (
        <FormControlLabel
          control={
            <Switch defaultChecked={!optional} onChange={handleChange} />
          }
          label={label}
        />
      ) : (
        label
      )}
      {open && (
        <ContentContainer>
          <Flex width="100%" flexDirection="column" paddingTop="10px">
            {children}
          </Flex>
        </ContentContainer>
      )}
    </StyledFormCard>
  )
}

export default FormCard
