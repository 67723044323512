import { createSlice } from '@reduxjs/toolkit'
import { Sale } from 'domain/types'
import { fetchSalesData } from './fetch'

export interface SalesState {
  sales: Sale[]
}

export const initialState: SalesState = {
  sales: [],
}

export const saleSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setSalesState: (state: SalesState, action) => {
      state.sales = action.payload
    },
  },
})

export const { setSalesState } = saleSlice.actions

export const getSales = async (): Promise<Sale[]> => {
  const data = await fetchSalesData()
  return data
}

export const fetchSales = () => async (dispatch: any) => {
  const data = await getSales()
  dispatch(setSalesState(data))
}

export default saleSlice
