import React from 'react'

import { Layout } from 'components/layout'
import { Routes, Route } from 'react-router-dom'
import Main from 'views/Marketing/Main'
import Create from './Create'

const Marketing: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/create" element={<Create />} />
      </Routes>
    </Layout>
  )
}

export default Marketing
