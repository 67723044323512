import React from 'react'

import { Layout } from 'components/layout'
import { Routes, Route } from 'react-router-dom'
import Main from 'views/Account/Main'

const Accounts: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
    </Layout>
  )
}

export default Accounts
