import {
  Box,
  Button,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Logout from '@mui/icons-material/Logout'

import { useNavigate, useLocation } from 'react-router-dom'
import React, { useCallback, useState } from 'react'
import config from './config'
import { useIsAdmin } from 'state/user/hooks'
import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth'

const drawerWidth = '256px'

const LoginBox = styled(Box)`
  position: absolute;
  bottom: 20px;
  padding: 24px;
  width: 100%;
`

const NavigationItems: React.FC = () => {
  const { account } = useWeb3React()

  const { logout } = useAuth()

  const navigate = useNavigate()
  const [collapsibleItem, setCollapsibleItem] = useState<{
    [key: string]: boolean
  }>({})

  const handleItemClicked = useCallback(
    (item?: string, link?: string) => {
      if (item) {
        setCollapsibleItem({
          ...collapsibleItem,
          [item]: !collapsibleItem[item],
        })
      }
      if (link) {
        navigate(link)
      }
    },
    [collapsibleItem, setCollapsibleItem, navigate],
  )
  const isSystemAdmin = useIsAdmin(['SYSTEM_ADMIN'])

  const location = useLocation()
  const path = location.pathname

  return (
    <>
      <Box mb="50px" mt="24px">
        <img src="/images/logo.svg" width="100%" alt="logo" />
      </Box>

      <List>
        {config(isSystemAdmin).map((configItem) => {
          return (
            <React.Fragment key={configItem.title}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={path.indexOf(configItem.link) > -1}
                  onClick={() =>
                    handleItemClicked(configItem.title, configItem.link)
                  }
                >
                  <ListItemIcon>{configItem.icon}</ListItemIcon>
                  <ListItemText primary={configItem.title} />
                  {configItem.children ? (
                    collapsibleItem[configItem.title] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItemButton>
              </ListItem>
              {configItem.children && (
                <Collapse
                  in={collapsibleItem[configItem.title]}
                  timeout="auto"
                  unmountOnExit
                >
                  {configItem.children?.map((itemChild) => (
                    <List component="div" disablePadding key={itemChild.title}>
                      <ListItemButton
                        selected={path.indexOf(itemChild.link) > -1}
                        onClick={() =>
                          handleItemClicked(undefined, itemChild.link)
                        }
                      >
                        <ListItemIcon>{itemChild.icon}</ListItemIcon>
                        <ListItemText primary={itemChild.title} />
                      </ListItemButton>
                    </List>
                  ))}
                </Collapse>
              )}
            </React.Fragment>
          )
        })}
      </List>
      <LoginBox>
        <Box overflow="hidden">
          <Typography align="left" fontWeight={500} variant="body1">
            Login Address
          </Typography>
          <Typography align="left" variant="body2" color="#FFFFFFB2">
            {`0x${account.slice(2, 2 + 4)}………${account.slice(
              account.length - 4,
            )}`}
          </Typography>
        </Box>
        <Box marginTop="16px">
          <Button
            variant="contained"
            sx={{ width: '100%' }}
            onClick={() => {
              logout()
              navigate('/login')
            }}
          >
            <Box display="flex" columnGap="8px">
              Log Out <Logout />
            </Box>
          </Button>
        </Box>
      </LoginBox>
    </>
  )
}

const Navigation: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [setMobileOpen, mobileOpen])

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            background: 'linear-gradient(0deg, #263042, #263042), #263042',
          },
        }}
      >
        <NavigationItems />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            background: 'linear-gradient(0deg, #263042, #263042), #263042',
          },
        }}
        open
      >
        <NavigationItems />
      </Drawer>
    </Box>
  )
}
export default Navigation
