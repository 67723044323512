import { Chain } from 'domain/types'

const fetchChainsData = async (): Promise<Chain[]> => {
  let chains: Chain[] = []

  const res = await fetch(`/api/backend-service/admin/chains`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  chains = data.data.chains
  return chains
}

const fetchPublicChainsData = async (): Promise<Chain[]> => {
  let chains: Chain[] = []

  const res = await fetch(`/api/backend-service/chains`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  chains = data.data.chains
  return chains
}

export { fetchChainsData, fetchPublicChainsData }
