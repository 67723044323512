import { createReducer } from '@reduxjs/toolkit'
import { setUserSignedState } from './actions'

export interface UserState {
  userSignedState: {
    isSigned: boolean
    isTotpValidated?: boolean
    expiry: number
    account?: string | null
    roles: string[]
  }
}

export const initialState: UserState = {
  userSignedState: { isSigned: false, expiry: 0, account: '', roles: [] },
}

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    setUserSignedState,
    (
      state,
      { payload: { isSigned, expiry, account, roles, isTotpValidated } },
    ) => {
      state.userSignedState = {
        isSigned,
        expiry,
        account,
        roles,
        isTotpValidated,
      }
    },
  )
})

export default userReducer
