import { Token } from 'domain/types'

const fetchTokensData = async (): Promise<Token[]> => {
  let tokens: Token[] = []

  const res = await fetch(`/api/backend-service/admin/tokens`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  tokens = data.data
  return tokens
}

export { fetchTokensData }
