import { configureStore } from '@reduxjs/toolkit'
import appReducer from './app'
import userReducer from './user/reducer'
import questReducer from './quest/reducer'
import chainReducer from './chain/reducer'
import tokenReducer from './token/reducer'
import toastReducer from './toast/reducer'
import dialogReducer from './dialog/reducer'
import projectReducer from './projects/reducer'
import accountReducer from './accounts/reducer'
import promotionReducer from './promotions/reducer'
import saleReducer from './sale/reducer'
import totpReducer from './totp/reducer'

import { save, load } from 'redux-localstorage-simple'

const PERSISTED_KEYS: string[] = ['user', 'app', 'chain']

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    app: appReducer,
    user: userReducer,
    quest: questReducer,
    token: tokenReducer.reducer,
    chain: chainReducer.reducer,
    project: projectReducer.reducer,
    account: accountReducer.reducer,
    promotion: promotionReducer.reducer,
    sale: saleReducer.reducer,
    totp: totpReducer.reducer,
    toast: toastReducer,
    dialog: dialogReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(save({ states: PERSISTED_KEYS })),
  preloadedState: load({ states: PERSISTED_KEYS }),
})

// Infer the `AppState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
