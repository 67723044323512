import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
})

export const colors = {
  dangerBg: '#580B0B4D',
  danger: '#FF8799',
}

export default theme
