import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { AppState } from 'state'

export function useIsUserSignedIn(): boolean {
  const { account } = useWeb3React()
  return useSelector<AppState, boolean>((state) => {
    if (!state?.user?.userSignedState) return false

    const {
      isSigned,
      expiry,
      account: signedAccount,
    } = state.user.userSignedState
    return (
      isSigned &&
      expiry > new Date().getTime() &&
      signedAccount === account?.toLowerCase()
    )
  })
}

export function useIsUserTotpValidated(): boolean {
  return useSelector<AppState, boolean>((state) => {
    if (!state?.user?.userSignedState) return false

    const { isTotpValidated } = state.user.userSignedState
    return isTotpValidated
  })
}

export function useIsAdmin(_roles?: string[]): boolean {
  return useSelector<AppState, boolean>((state) => {
    if (!state?.user?.userSignedState) return false

    const { roles } = state?.user?.userSignedState
    return (
      roles?.findIndex((role) =>
        _roles
          ? _roles
          : [
              'ADMIN',
              'INTERNAL_ADMIN',
              'EXTERNAL_ADMIN',
              'SYSTEM_ADMIN',
            ].includes(role),
      ) !== -1
    )
  })
}
