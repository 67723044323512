const useFormat = () => {
  const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 KB'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    // less than 1 KB shows 1 KB
    if (i === 0) {
      return '1 KB'
    } else {
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }
  }

  return { formatBytes }
}

export default useFormat
