import { useCallback, useState } from 'react'
import useToast from './useToast'

export const useAccount = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { showToast } = useToast()

  const createAccount = useCallback(
    (body: any) => {
      setIsLoading(true)
      fetch(`/api/backend-service/admin/account`, {
        credentials: 'include',
        headers: [['Cache-Control', 'no-cache']],
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          }
        })
        .then(() => {
          showToast({
            message: `Account is successfully created`,
            type: 'success',
          })
        })
        .catch((error) => {
          showToast({
            message: `Error occurred: ${error}`,
            type: 'error',
          })
          setIsLoading(false)
        })
    },
    [showToast],
  )

  const updateAccount = useCallback(
    (body: any) => {
      setIsLoading(true)
      fetch(`/api/backend-service/admin/account/${body.id}`, {
        credentials: 'include',
        headers: [['Cache-Control', 'no-cache']],
        method: 'PUT',
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          }
        })
        .then(() => {
          showToast({
            message: `Account is successfully updated`,
            type: 'success',
          })
        })
        .catch((error) => {
          showToast({
            message: `Error occurred: ${error}`,
            type: 'error',
          })
          setIsLoading(false)
        })
    },
    [showToast],
  )

  const deleteAccount = useCallback(
    (body: any) => {
      setIsLoading(true)
      fetch(`/api/backend-service/admin/account/${body.id}`, {
        credentials: 'include',
        headers: [['Cache-Control', 'no-cache']],
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          }
        })
        .then(() => {
          showToast({
            message: `Account is successfully deleted`,
            type: 'success',
          })
        })
        .catch((error) => {
          showToast({
            message: `Error occurred: ${error}`,
            type: 'error',
          })
          setIsLoading(false)
        })
    },
    [showToast],
  )

  return { createAccount, updateAccount, deleteAccount, isLoading }
}

export default useAccount
