import { Totp } from 'domain/types'

const fetchTOTPStatus = async (): Promise<Totp> => {
  let totp: Totp = {
    state: '',
    qrCode: '',
  }

  const res = await fetch(`/api/backend-service/totp/status`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  totp = data.data
  return totp
}

export { fetchTOTPStatus }
