import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import { flexbox } from 'styled-system'
import { FlexProps } from './types'

const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${flexbox}
`

export default Flex
