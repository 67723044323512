import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { connectorsByName } from 'utils/web3React'
import { setUnavailable, clearUnavailable } from 'state/app'

import getProvider from 'utils/getProvider'
import { useDispatch } from 'react-redux'
import { setUserSignedState } from 'state/user/actions'
import { ConnectorNames } from 'constants/types'
import useChains from 'state/chain/hooks'

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const chains = useChains()
  const dispatch = useDispatch()

  const onChainChanged = useCallback(
    (chainID) => {
      const intChainId = parseInt(chainID)
      const networkData = chains.find((item) => item.chainID === intChainId)
      if (networkData) {
        dispatch(clearUnavailable())
      }
      return intChainId
    },
    [dispatch, chains],
  )

  const login = useCallback(async (connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]
    dispatch(clearUnavailable())
    if (connector) {
      await activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          dispatch(setUnavailable())
        } else {
          window.localStorage.removeItem('connectorId')
        }
      })
      window.localStorage.setItem('connectorId', connectorID)

      const provider = getProvider()
      if (provider) {
        const chainID = await provider.request({ method: 'eth_chainId' })
        onChainChanged(chainID)
        provider.on('chainChanged', (chainIDData) =>
          onChainChanged(chainIDData),
        )

        provider.removeAllListeners(['networkChanged'])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = useCallback(() => {
    deactivate()
    const provider = getProvider()
    if (provider) {
      provider.removeListener('chainChanged', onChainChanged)
    }
    dispatch(
      setUserSignedState({
        isSigned: false,
        expiry: 0,
        account: '',
        roles: [],
      }),
    )
  }, [deactivate, dispatch, onChainChanged])

  return { onChainChanged, login, logout }
}

export default useAuth
