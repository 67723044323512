import { createContext, useState } from 'react'

export interface ModalPresentOptions {
  backdropBlurVal?: string | null
  backgroundColorVal?: string | null
  modalTitle?: string
  confirmButton?: {
    title: string
    onClick?: () => void
  }
  modalIcon?: React.ReactNode
}

interface ModalsContext {
  isOpen: boolean
  nodeId: string
  bodyNode: React.ReactNode
  setBodyNode: React.Dispatch<React.SetStateAction<React.ReactNode>>
  onPresent: (
    node: React.ReactNode,
    newNodeId: string,
    options?: ModalPresentOptions,
  ) => void
  onDismiss: () => void
  modalTitle: string
  confirmButton: {
    title: string
    onClick?: () => void
  }
  modalIcon: React.ReactNode
}

interface Props {
  children: React.ReactNode
}

export const Context = createContext<ModalsContext>({
  isOpen: false,
  nodeId: '',
  bodyNode: null,
  setBodyNode: () => null,
  onPresent: () => null,
  onDismiss: () => null,
  modalTitle: '',
  confirmButton: { title: '' },
  modalIcon: null,
})

const ModalProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [bodyNode, setBodyNode] = useState<React.ReactNode>()
  const [modalIcon, setModalIcon] = useState<React.ReactNode>()
  const [nodeId, setNodeId] = useState('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const [confirmButton, setConfirmButton] = useState<{
    title: string
    onClick?: () => void
  }>({ title: '' })
  const handlePresent: ModalsContext['onPresent'] = (
    bodyNode,
    newNodeId,
    options,
  ) => {
    setBodyNode(bodyNode)
    setIsOpen(true)
    setNodeId(newNodeId)
    setModalTitle(options?.modalTitle || '')
    setConfirmButton(options?.confirmButton || { title: 'Confirm' })
    setModalIcon(options?.modalIcon)
  }

  const handleDismiss = () => {
    setBodyNode(undefined)
    setIsOpen(false)
    setNodeId('')
    setModalIcon(null)
  }

  return (
    <Context.Provider
      value={{
        isOpen,
        nodeId,
        bodyNode,
        setBodyNode,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        modalTitle,
        confirmButton,
        modalIcon,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ModalProvider
