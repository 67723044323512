import { Suspense, useContext } from 'react'
import { Box, Modal as MuiModal, Typography } from '@mui/material'
import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'
import { Context } from './ModalContext'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: 300,
  transform: 'translate(-50%, -50%)',
  bgcolor: '#1D2634',
  boxShadow: 24,
  p: 3,
  display: 'flex',
  flexDirection: 'column',
}

const Modal: React.FC = () => {
  const { isOpen, onDismiss, bodyNode, modalTitle, confirmButton, modalIcon } =
    useContext(Context)

  return (
    <>
      <MuiModal
        open={isOpen}
        onClose={onDismiss}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>{modalIcon}</Box>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalBody
            style={{
              paddingTop: 1,
              paddingBottom: 4,
            }}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <Typography variant="body2" textAlign="center">
                {bodyNode}
              </Typography>
            </Suspense>
          </ModalBody>

          <ModalFooter
            onDismiss={onDismiss}
            confirmButton={confirmButton.title}
            onConfirm={confirmButton.onClick}
          />
        </Box>
      </MuiModal>
    </>
  )
}

export default Modal
