import { createSlice } from '@reduxjs/toolkit'
import { TPromotion } from 'domain/types'
import { fetchPromotionsData } from './fetch'

export interface PromotionState {
  promotions: TPromotion[]
}

export const initialState: PromotionState = {
  promotions: [],
}

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setPromotionsState: (state: PromotionState, action) => {
      state.promotions = action.payload
    },
  },
})

export const { setPromotionsState } = promotionSlice.actions

export const getPromotions = async (): Promise<TPromotion[]> => {
  const data = await fetchPromotionsData()
  return data
}

export const fetchPromotions = () => async (dispatch: any) => {
  const data = await getPromotions()
  dispatch(setPromotionsState(data))
}

export default promotionSlice
