import { useEffect } from 'react'
import useAuth from 'hooks/useAuth'
import { useIsNetworkUnavailable } from 'state/app/hooks'
import { ConnectorNames } from 'constants/types'

const useEagerConnect = () => {
  const { login } = useAuth()
  const isUnavailable = useIsNetworkUnavailable()

  useEffect(() => {
    const connectorId = window.localStorage.getItem(
      'connectorId',
    ) as ConnectorNames

    if (connectorId && !isUnavailable) {
      login(connectorId)
    }
  }, [login, isUnavailable])
}

export default useEagerConnect
