import {
  UseFormTrigger,
  FieldValues,
  UseFormRegister,
  FieldErrorsImpl,
  Control,
} from 'react-hook-form'

export interface FormProps {
  trigger: UseFormTrigger<FieldValues>
  register: UseFormRegister<FieldValues>
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any
    }>
  >
  control: Control<FieldValues, any>
  optional?: boolean
  onChange?: (value: boolean) => void
  onFieldChange?: (data: any) => void
}

interface FormFields {
  formType: FormType
  key: string
  optional: boolean
}

export type FormType =
  | 'BannerInfo'
  | 'Landing'
  | 'ExploreKeyBanner'
  | 'ExploreKeyBannerWithTimer'
  | 'AccountSidebar'

// The formsa are defined in order of appearance in the creation page
export const ALL_FORMS: FormFields[] = [
  {
    formType: 'BannerInfo',
    key: null,
    optional: false,
  },
  {
    formType: 'Landing',
    key: 'landing',
    optional: true,
  },
  {
    formType: 'ExploreKeyBanner',
    key: 'explorer',
    optional: true,
  },
  {
    formType: 'ExploreKeyBannerWithTimer',
    key: 'timeExplorer',
    optional: true,
  },
  {
    formType: 'AccountSidebar',
    key: 'sidebar',
    optional: true,
  },
]
