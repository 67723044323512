import { Box, Button } from '@mui/material'

interface Props {
  onDismiss?: () => void
  onConfirm?: () => void
  confirmButton?: string
}

const ModalFooter: React.FC<Props> = ({
  onConfirm,
  onDismiss,
  confirmButton,
}) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
    >
      <Button
        fullWidth
        sx={{ mr: 1, bgcolor: '#90A4AE' }}
        onClick={onDismiss}
        variant="contained"
      >
        Cancel
      </Button>
      <Box sx={{ marginLeft: 1 }} />
      <Button fullWidth onClick={onConfirm} variant="contained">
        {confirmButton}
      </Button>
    </Box>
  )
}

export default ModalFooter
