/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'

import { Box, Modal, useTheme } from '@mui/material'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Noop } from 'react-hook-form'
import styled from '@emotion/styled'
import FileUpload from 'components/FileUpload'
import './styles.css'

const StyledReactQuill = styled(ReactQuill)`
  color: rgba(255, 255, 255, 0.5);
  .ql-container {
    min-height: 95px; // Set the minimum height
  }
  .ql-picker-item {
    color: black;
  }
  .ql-editor::before {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
  }

  .ql-toolbar .ql-stroke {
    fill: none;
    stroke: rgba(255, 255, 255, 0.7);
  }

  .ql-toolbar button.ql-active .ql-stroke {
    stroke: rgba(255, 255, 255, 0.9);
  }

  .ql-toolbar .ql-fill {
    fill: #fff;
    stroke: none;
  }

  .ql-toolbar button.ql-active .ql-fill {
    stroke: rgba(255, 255, 255, 0.9);
  }

  .ql-toolbar .ql-picker {
    color: rgb(255, 255, 255, 0.7);
  }

  .ql-toolbar button.ql-active .ql-picker {
    stroke: rgba(255, 255, 255, 0.9);
  }

  .ql-undo:: hover {
    color:blue
    pading: 8px;
  }
  .ql-redo {
    pading: 8px;
  }
`
interface projectDetail {
  title: string
  description: string
}

interface Props {
  onChange?: (...event: any[]) => void
  onBlur?: Noop
  value?: string | undefined
  name?: string
  style?: React.CSSProperties
  placeholder?: string
  error?: any
  defaultValue?: projectDetail
}
const icons = Quill.import('ui/icons')
icons['redo'] = '<img src="/images/quill/undo.svg" class="ql-undo">'
icons['undo'] = '<img src="/images/quill/redo.svg" class="ql-redo">'

// TODO: Redo and Undo buttons
const RichTextEditor: React.FC<Props> = forwardRef((props, ref) => {
  const { value, onChange = () => {}, style, placeholder, error } = props
  const theme = useTheme()
  const quillRef = useRef(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const insertImage = useCallback((imageUrl) => {
    const editor = quillRef.current.getEditor()
    const range = editor.getSelection()
    if (range) {
      editor.insertEmbed(range.index, 'image', imageUrl)
    }
  }, [])

  const ImageUploadModal = useCallback(
    () => (
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box>
          <FileUpload
            onChange={(url) => {
              insertImage(url)
              setIsModalOpen(false)
            }}
          />
        </Box>
      </Modal>
    ),
    [],
  )

  const imageHandler = useMemo(() => {
    setIsModalOpen(true)
  }, [])

  const handleUndo = () => {
    const editor = quillRef.current?.getEditor()
    editor?.history.undo()
  }

  const handleRedo = () => {
    const editor = quillRef.current?.getEditor()
    editor?.history.redo()
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['undo', 'redo'],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image'],
          ['clean'],
        ],
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true,
        },
        handlers: {
          undo: handleUndo,
          redo: handleRedo,
          image: imageHandler,
        },
      },
    }),
    [],
  )

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  return (
    <Box>
      <StyledReactQuill
        /*         defaultValue={defaultValue}
         */ theme="snow"
        value={value}
        placeholder={placeholder}
        ref={quillRef}
        onChange={(value) => {
          // This is to solve empty text editor being <p><br /><p>, causing Required true is invalid
          /* if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            onChange('')
          } else { */
          onChange(value)
        }}
        modules={modules}
        formats={formats}
        style={{
          color: theme.palette.text.primary,
          ...style,

          borderBottomColor: '#f44336',
        }}
      />

      {error && (
        <Box
          style={{
            color: '#f44336',
            fontSize: '0.75rem',
            lineHeight: '1.66',
            letterSpacing: '0.03333em',
            marginTop: '3px',
            marginLeft: '14px',
            fontFamily: 'Roboto","Helvetica","Arial",sans-serif',
          }}
        >
          {error.message}
        </Box>
      )}
      <ImageUploadModal />
    </Box>
  )
})

export default RichTextEditor
