import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import loGet from 'lodash/get'
import React, { useEffect } from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import { useAppDispatch } from 'state/hooks'
import { fetchChains } from 'state/chain/reducer'
import { DefaultValueMap } from '..'

type DropdownProps = {
  name: string
  label?: string
  options: string[]
  isRequired?: boolean
  disabled?: boolean
  control?: Control<DefaultValueMap, any>
  errors: FieldErrorsImpl<{
    [x: string]: any
  }>
  minWidth?: string
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  options,
  control,
  isRequired,
  label,
  errors,
  disabled,
  minWidth,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchChains())
  }, [dispatch])

  return (
    <Controller
      control={control}
      name={name}
      rules={isRequired ? { required: 'This field is required.' } : {}}
      render={({ field }) => (
        <>
          <FormControl
            variant="filled"
            sx={{ minWidth: minWidth || 340 }}
            error={!!loGet(errors, name)}
          >
            <InputLabel>{label}</InputLabel>
            <Select
              disabled={disabled}
              value={field.value || ''}
              onChange={(evt) => field.onChange(evt.target.value)}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {loGet(errors, name)?.message as string}
            </FormHelperText>
          </FormControl>
        </>
      )}
    />
  )
}

export default Dropdown
