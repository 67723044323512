import { useSelector } from 'react-redux'
import { SimpleToken, Token } from 'domain/types'
import { AppState } from 'state'

export const useTokens = (): Token[] => {
  const data = useSelector((state: AppState) => {
    return state.token.tokens || []
  })
  return data
}

export const useToken = (tokenId?: number): Token => {
  const tokens = useTokens()
  return tokenId && tokens.find((token) => token.tokenId === tokenId)
}

export const useSimpleToken = (
  tokenId: number,
  chainId: number,
): SimpleToken => {
  const tokens = useTokens()
  const token = tokens.find((token) => token.tokenId === tokenId)
  if (!token) return null
  const address = token.addresses.find((value) => value.chainId === chainId)

  return {
    tokenId: token.tokenId,
    tokenSymbol: token.tokenSymbol,
    tokenName: token.tokenName,
    tokenImage: token.tokenImage,
    tokenAddress: address?.tokenAddress,
    decimals: address?.decimals,
  }
}

export default useTokens
