import { useCallback, useState } from 'react'

const useFileUploader = () => {
  const [isLoading, setIsLoading] = useState(false)

  const uploadFile = useCallback(
    (
      body: {
        file: Blob
        path: string
      },
      onSucceed?: (res: any) => void,
      onFailure?: (error: any) => void,
    ) => {
      const formData = new FormData()
      formData.append('file', body.file)
      formData.append('path', body.path)

      fetch(`/api/backend-service/image/upload`, {
        credentials: 'include',
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          }
          return res.data
        })
        .then(onSucceed)
        .catch((error) => {
          setIsLoading(false)
          if (onFailure) onFailure(error)
        })
    },
    [],
  )

  return { uploadFile, isLoading }
}

export default useFileUploader
