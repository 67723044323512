import React, { useCallback, useMemo, useState } from 'react'
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Table,
  IconButton,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  TableSortLabel,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import { ChevronRight } from '@mui/icons-material'

type TTableData = any & {
  action: ''
  edit: ''
}

export interface Column {
  id: string | any
  label: string
  type?: string
  editAction?: (param: any, index: number) => void
  minWidth?: number
  maxWidth?: number
  align?: 'right'
  format?: (value: number) => string
  customRender?: (row: any) => React.ReactElement
  sortable?: boolean
}

interface Props {
  data: any[]
  columns: Column[]
  onClickRow?: (row) => void
  searchField?: string
}

enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

function descendingComparator(a, b, orderBy) {
  const aData = a[orderBy] || ''
  const bData = b[orderBy] || ''

  if (bData < aData) {
    return -1
  }
  if (bData > aData) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const ListTable: React.FC<Props> = ({
  data = [],
  columns = [],
  onClickRow,
  searchField,
}) => {
  const [search, setSearch] = useState<string>()
  const [order, setOrder] = React.useState<Direction>(Direction.ASC)
  const [orderBy, setOrderBy] = React.useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleSearch = useCallback(
    (evt) => {
      setSearch(evt.target.value)
      setPage(0)
    },
    [setSearch],
  )

  const tableData: TTableData[] = useMemo(() => {
    const comparator = getComparator(order, orderBy)
    let sortedData = orderBy
      ? data.sort((a, b) => {
          const order = comparator(a, b)
          if (order !== 0) {
            return order
          }
          return a[1] - b[1]
        })
      : data

    let filteredData = sortedData

    if (search) {
      filteredData = data.filter(
        (item) =>
          !search ||
          item[searchField]?.toLowerCase()?.includes(search.toLowerCase()),
      )
    }
    return filteredData.map((item) => {
      return {
        ...item,
        action: '',
        edit: '',
      }
    })
  }, [data, order, orderBy, search, searchField])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? Direction.DESC : Direction.ASC)
    setOrderBy(property)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const renderTableCell = useCallback(
    (index: number, column: Column, row?: any) => {
      const value = row[column.id] || ''

      if (!!column.customRender) {
        return column.customRender(row)
      }

      switch (column.type) {
        case 'view':
          return (
            <IconButton
              aria-label="view"
              color="primary"
              onClick={() => {
                if (column?.editAction) {
                  column.editAction(row, index)
                }
              }}
            >
              <ChevronRight />
            </IconButton>
          )
        case 'edit':
          return (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                if (column?.editAction) {
                  column.editAction(row, index)
                }
              }}
            >
              <EditIcon />
            </IconButton>
          )
        default:
          return <Typography>{value || '-'}</Typography>
      }
    },
    [],
  )

  return (
    <>
      {searchField && (
        <Grid container spacing={2} mb={5}>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="Search"
              onChange={handleSearch}
              placeholder="Title"
              sx={{ minWidth: '350px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #263042',
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  border: '1px solid rgba(255, 255, 255, 0.12)',
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      background:
                        'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #263042',
                    }}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={(e) => handleRequestSort(e, column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      <>{column.label} </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`row${index}`}
                      onClick={() => onClickRow?.(row)}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ borderBottom: 'none' }}
                          >
                            {renderTableCell(index, column, row)}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

export default ListTable
