import {
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import theme from 'theme'
import { Flex } from 'uikit/components/Svg/Box'
import { Roles, RolesMap } from './types'
import { TAccount } from 'domain/types'
import { invert } from 'lodash'
import Web3 from 'web3'
import useAccounts from 'state/accounts/hooks'

const RadioTextDescription = ({ children }) => {
  return (
    <Typography
      fontSize="12px"
      lineHeight="18px"
      fontWeight="400"
      color={theme.palette.text.secondary}
      paddingLeft="32px"
    >
      {children}
    </Typography>
  )
}

interface Props {
  onClose: () => void
  onSubmit: (data: any) => void
  defaultValues?: TAccount
  addressEditable?: boolean
  noDuplicateAddress?: boolean
}

const InputForm: React.FC<Props> = ({
  onClose,
  onSubmit,
  defaultValues,
  addressEditable = true,
  noDuplicateAddress,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({ defaultValues })
  const accounts = useAccounts()
  const onSubmitForm = (data: any) => {
    data.role = invert(RolesMap)[data.role]
    onSubmit(data)
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      {/* Text Fields */}
      <Flex width="100%" flexDirection="column">
        <Controller
          control={control}
          name="address"
          rules={{
            required: 'This field is required.',
            validate: {
              required: (value) => {
                if (!Web3.utils.isAddress(value)) {
                  return 'The address format is not valid'
                }
                if (
                  noDuplicateAddress &&
                  accounts.some((account) => account.address === value)
                ) {
                  return 'The address already exists'
                }
              },
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              variant="filled"
              label="Address"
              margin="normal"
              placeholder="Enter Address"
              sx={{ border: fieldState.error ? '1px solid red' : '' }}
              helperText={errors?.address?.message as string}
              disabled={!addressEditable}
            />
          )}
        />
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              variant="filled"
              label="Name (optional)"
              margin="normal"
              placeholder="Admin Name"
            />
          )}
        />
      </Flex>

      {/* Radio Buttons */}
      <Flex
        alignSelf="flex-start"
        alignItems="flex-start"
        padding="30px 0px 55px"
        component="form"
      >
        <Controller
          control={control}
          name="role"
          render={({ field }) => (
            <FormControl {...field}>
              <FormLabel id="radio-buttons-group-label">
                <Typography
                  fontSize="16px"
                  lineHeight="22px"
                  fontWeight="400"
                  color="white"
                >
                  Role Select
                </Typography>
              </FormLabel>
              <Flex paddingLeft="10px">
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  defaultValue={defaultValues?.role || Roles.InternalAdmin}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Admin"
                    control={<Radio />}
                    label="Admin"
                  />
                  <RadioTextDescription>
                    Authorized to create, edit, publish: project profile,
                    martketing materials, Impossible Quest program.
                  </RadioTextDescription>
                  <Flex height="15px" />
                  <FormControlLabel
                    value="Project Partners"
                    control={<Radio />}
                    label="Project Partners"
                  />
                  <RadioTextDescription>
                    Limited access: create profile, create token sales (need to
                    submit and get approve to publish the sale)
                  </RadioTextDescription>
                </RadioGroup>
              </Flex>
            </FormControl>
          )}
        />
      </Flex>

      {/* Footer */}
      <Flex width="100%" height="42px" justifyContent="space-around">
        <Flex width="90%" justifyContent="space-between">
          <Button
            variant="contained"
            onClick={() => onClose()}
            sx={{ width: '49%', backgroundColor: '#90A4AE' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isDirty}
            sx={{ width: '49%' }}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}

export default InputForm
