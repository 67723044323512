import { createReducer } from '@reduxjs/toolkit'
import { setToastState } from './actions'
import { TToast } from 'domain/types'

export interface ToastState {
  toastState: TToast
}

export const initialState: ToastState = {
  toastState: {
    open: false,
    type: 'success',
    message: '',
    direction: {
      vertical: 'top',
      horizontal: 'right',
    },
    duration: 3000,
  },
}

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(setToastState, (state, { payload }) => {
    state.toastState = { ...state.toastState, ...payload }
  })
})

export default userReducer
