export enum Roles {
  SystemAdmin = 'System Admin',
  InternalAdmin = 'Admin',
  ProjectPartners = 'Project Partners',
}

export const RolesMap = {
  SYSTEM_ADMIN: Roles.SystemAdmin,
  INTERNAL_ADMIN: Roles.InternalAdmin,
  PROJECT_ADMIN: Roles.ProjectPartners,
  // legacy role
  ADMIN: Roles.SystemAdmin,
}
