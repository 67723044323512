import { Typography } from '@mui/material'
import StartEndDate from 'components/AutoForm/Components/StartEndDate'
import GeneratedFormFields, {
  FieldType,
} from 'components/AutoForm/GeneratedFormFields'
import { Flex } from 'uikit/components/Svg/Box'
import FormCard from '../widgets/FormCard'
import { FormProps } from './type'

const ExploreKeyBanner: React.FC<FormProps> = ({
  optional,
  onChange,
  register,
  errors,
  control,
}) => {
  return (
    <FormCard
      header="Explore - Key banner"
      optional={optional}
      onChange={onChange}
    >
      <Flex flexDirection="column" rowGap="8px">
        <Typography fontSize="16px">Event Period</Typography>
        <Typography fontSize="14px" color="#c1c4c9">
          When you setup end date, the banner will be hidden when the event
          ends.
        </Typography>
        <StartEndDate
          control={control}
          errors={errors}
          startTime={{
            name: 'exploreKeyBanner.startTime',
            title: 'Publish Start Date & Time',
            isRequired: true,
          }}
          endTime={{
            name: 'explorerKeyBanner.endTime',
            title: 'End Date & Time (optional)',
            isRequired: false,
          }}
        />
        <Flex height="4px" />
        <GeneratedFormFields
          register={register}
          errors={errors}
          control={control}
          formFields={[
            {
              type: FieldType.STRING,
              title: 'URL',
              name: 'url',
              isRequired: true,
            },
          ]}
        />
        <GeneratedFormFields
          register={register}
          errors={errors}
          control={control}
          formFields={[
            {
              type: FieldType.DROPDOWN,
              title: 'Tag',
              name: 'tag',
              options: ['Event', 'Upcoming', 'Live Now!'],
              isRequired: true,
            },
          ]}
        />

        <Typography fontSize="16px">Upload Image</Typography>
        <Typography fontSize="14px" color="#c1c4c9">
          For mobile and desktop, please upload the same image size: 574*300 px{' '}
        </Typography>
        <GeneratedFormFields
          register={register}
          errors={errors}
          control={control}
          formFields={[
            {
              type: FieldType.IMAGE,
              title: '',
              name: 'deskTopImageUrl',
              placeholder: '',
              isRequired: true,
            },
          ]}
        />
      </Flex>
    </FormCard>
  )
}

export default ExploreKeyBanner
