import { shallowEqual, useSelector } from 'react-redux'
import { TPromotion } from 'domain/types'
import { AppState } from 'state'

export const usePromotions = (): TPromotion[] => {
  const data = useSelector((state: AppState) => {
    return state.promotion.promotions || []
  }, shallowEqual)
  return data
}

export default usePromotions
