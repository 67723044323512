import { styled, Typography } from '@mui/material'
import React from 'react'
import { Flex } from 'uikit/components/Svg/Box'
import DeleteIcon from '@mui/icons-material/Delete'
import Icon from './Icon'
import theme from 'theme'
import InputForm from './InputForm'
import { TAccount } from 'domain/types'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import useDialog from 'hooks/useDialog'

const StyledFlex = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #263042;
  border: 2px solid #000;
  padding: 4px;
`

const DeleteAccountFlex = styled(Flex)`
  color: #91b6ff;
  border: solid 0.5px;
  border-color: #91b6ff;
  border-radius: 25px;
  padding: 4px 15px;
  margin: 20px 0px;
  cursor: pointer;
`

interface Props {
  data: TAccount
  onSubmit: (data: any) => void
  onClose: () => void
  onDelete: (data: any) => void
}

const EditAccountModal: React.FC<Props> = ({
  data,
  onSubmit,
  onClose,
  onDelete,
}) => {
  const { showDialog } = useDialog()
  const onSubmitEdit = (_data: any) => {
    _data.id = data.id
    onSubmit(_data)
    onClose()
  }

  // Confirm Modal
  const handleConfirmClick = () => {
    showDialog({
      title: 'Heads Up',
      icon: <WarningAmberIcon sx={{ fontSize: '58px', fill: '#FFB74D' }} />,
      content: `You are deleting ${data.name}\n ${data.address}`,
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Cancel',
      variant: 'warning',
      onConfirm: () => {
        onDelete(data)
        onClose()
      },
      // Not to close EditAccountModal on cancel
      onCancel: () => {},
      style: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
      },
    })
  }

  // Edit Account Modal
  return (
    <StyledFlex>
      <Flex
        alignContent="center"
        alignItems="center"
        margin="auto"
        flexDirection="column"
        padding="44px 24px 24px"
      >
        {/* Modal header */}
        <Typography paddingBottom="32px" variant="h5">
          Edit Account Role
        </Typography>
        <Icon role={data.role} size="52px" backgroundColor="#282E3A" />
        <Typography paddingTop="8px" color={theme.palette.text.secondary}>
          {data.role} - {data.name}
        </Typography>
        <DeleteAccountFlex
          onClick={() => {
            handleConfirmClick()
          }}
        >
          <DeleteIcon sx={{ paddingRight: '5px' }} />
          <Typography fontSize="13px" lineHeight="25px">
            DELETE ACCOUNT
          </Typography>
        </DeleteAccountFlex>

        {/* Input */}
        <InputForm
          defaultValues={data}
          onClose={onClose}
          onSubmit={onSubmitEdit}
          addressEditable={false}
        />
      </Flex>
    </StyledFlex>
  )
}

export default EditAccountModal
