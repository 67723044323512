import { createReducer } from '@reduxjs/toolkit'
import { TQuestForm } from 'domain/types'
import { setQuestState } from './actions'

export interface QuestState {
  questForm: TQuestForm
}

export const initialState: QuestState = {
  questForm: {
    refProjectId: null,
    quizTitle: '',
    chainId: 0,
    minimumPassingScore: 80,
    maxRetry: undefined,
    academyLink: '',
    quizImage: '',
    quizBanner: '',
    quizLevel: null,
    hasReward: false,
    quizStatus: 0,
    quizRewards: undefined,
    quizDescription: '',
    quizArticle: '',
    tokenAddressId: null,
    articleDuration: null,
    startTime: '',
    endTime: null,
    totalRewards: undefined,
    // TODO: Use BE to hardcode this instead
    contractId: 2,
    questions: [
      {
        questionTitle: 'New Question',
        questionDescription: '',
        questionStatus: 0,
        questionType: 0,
        options: [{ optionTitle: '', isCorrect: false, optionStatus: 0 }],
      },
    ],
    requireKYC: true,
  },
}

const questReducer = createReducer(initialState, (builder) => {
  builder.addCase(setQuestState, (state, { payload }) => {
    state.questForm = { ...state.questForm, ...payload }
  })
})

export default questReducer
