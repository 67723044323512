import React, { useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Grid,
  InputAdornment,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import PromotionTable from './Table'
import { TPromotion } from 'domain/types'
import { useAppDispatch } from 'state/hooks'
import { fetchPromotions } from 'state/promotions/reducer'
import usePromotions from 'state/promotions/hooks'
import useBanner from 'hooks/useBanner'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import useDialog from 'hooks/useDialog'
import { useNavigate } from 'react-router-dom'

const Main: React.FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const promotions = usePromotions()
  const navigate = useNavigate()
  const { showDialog } = useDialog()
  const { getBanner, deleteBanner, isLoading } = useBanner()

  const [searchText, setSearchText] = useState('')

  const handleRowClickDelete = (rowData: TPromotion) => {
    showDialog({
      title: 'Delete Promotion',
      icon: <WarningAmberIcon sx={{ fontSize: '58px', fill: '#FFB74D' }} />,
      content: `You are deleting ${rowData.bannerTitle}`,
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Cancel',
      variant: 'warning',
      onConfirm: () => {
        deleteBanner(rowData.id)
      },
      onCancel: () => {},
      style: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        minWidth: '444px',
      },
    })
  }

  const handleRowClickEdit = (rowData: TPromotion) => {
    const defaultValues = getBanner(rowData.id)
    defaultValues.then((res) => {
      navigate(`/marketing/create`, { state: { defaultValues: res } })
    })
  }

  useEffect(() => {
    dispatch(fetchPromotions())
    // re-dispatch when useBanner executes actions
  }, [dispatch, isLoading])

  const filteredPromotions = useMemo(() => {
    return promotions.filter((promotion) => {
      return (
        (promotion.bannerTitle.includes(searchText) ||
          promotion.lastEditor.includes(searchText)) &&
        promotion.isActive
      )
    })
  }, [promotions, searchText])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h4">Marketing</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => navigate(`/marketing/create`)}
          >
            <Typography
              color="black"
              fontWeight="500"
              fontSize="14px"
              lineHeight="24px"
            >
              Add Promotion
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* Search bar */}
      <Grid container spacing={2} mb={5}>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            id="filter-tags-filled"
            options={promotions.map((promotion) => promotion.bannerTitle)}
            freeSolo
            onChange={(val) => setSearchText(val.currentTarget.textContent)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Search"
                placeholder="project name, last editor"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>

      {/* Table */}
      {filteredPromotions.length === 0 ? (
        <Box
          minHeight={300}
          color={theme.palette.background.paper}
          sx={{
            bgcolor: '#263042',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body1"
            color={theme.palette.text.primary}
            marginBottom={1}
          >
            You don't have any promotion yet.
          </Typography>
          <Button variant="contained">Add Promotion</Button>
        </Box>
      ) : (
        <Box>
          <PromotionTable
            onRowClickEdit={handleRowClickEdit}
            onRowClickDelete={handleRowClickDelete}
            data={filteredPromotions as TPromotion[]}
          />
        </Box>
      )}
    </Box>
  )
}

export default Main
