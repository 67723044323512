import { useEffect, useCallback, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import AccountSidebar from './AccountSidebar'
import BannerInfo from './BannerInfo'
import ExploreKeyBanner from './ExploreKeyBanner'
import ExploreKeyBannerWithTimer from './ExplorerKeyBannerWithTimer'
import Landing from './Landing'
import { FormType } from './type'

interface Props {
  formType: FormType
  optional: boolean
  onChange: (filled: boolean) => void
  onFieldChanged: (data: any, key?: string) => void
  shouldValidate: number
  defaultValues?: any
}

const MasterForm: React.FC<Props> = ({
  formType,
  optional,
  onChange,
  onFieldChanged,
  shouldValidate,
  defaultValues,
}) => {
  const {
    register,
    trigger,
    formState: { errors, isValid, isDirty },
    control,
    getValues,
  } = useForm({ defaultValues: defaultValues, mode: 'onBlur' })

  const [toggleOff, setToggleOff] = useState(optional)

  const watchAll = useWatch({ control })

  // update field states on field change
  useEffect(() => {
    const values = getValues()
    if (toggleOff) {
      onFieldChanged({})
    } else {
      onFieldChanged(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, toggleOff])

  useEffect(() => {
    if (shouldValidate > 0) {
      trigger()
    }
    // onFieldChange(data)
  }, [trigger, shouldValidate])

  // update sidebar ticks on toggle on / off
  const handleChange = useCallback(
    (toggleOn: boolean) => {
      // a form is valid if it is not selected OR filled
      onChange(!toggleOn || isValid)
      setToggleOff(!toggleOff)
    },
    [isValid, onChange, toggleOff],
  )

  useEffect(() => {
    if (!toggleOff) {
      onChange(isValid)
    } else {
      onChange(true)
    }
  }, [isDirty, isValid, onChange, toggleOff])

  switch (formType) {
    case 'BannerInfo':
      return (
        <BannerInfo
          optional={optional}
          onChange={handleChange}
          control={control}
          trigger={trigger}
          register={register}
          errors={errors}
        />
      )
    case 'Landing':
      return (
        <Landing
          optional={optional}
          onChange={handleChange}
          control={control}
          trigger={trigger}
          register={register}
          errors={errors}
        />
      )
    case 'ExploreKeyBanner':
      return (
        <ExploreKeyBannerWithTimer
          optional={optional}
          onChange={handleChange}
          control={control}
          trigger={trigger}
          register={register}
          errors={errors}
        />
      )
    case 'ExploreKeyBannerWithTimer':
      return (
        <ExploreKeyBanner
          optional={optional}
          onChange={handleChange}
          control={control}
          trigger={trigger}
          register={register}
          errors={errors}
        />
      )
    case 'AccountSidebar':
      return (
        <AccountSidebar
          optional={optional}
          onChange={handleChange}
          control={control}
          trigger={trigger}
          register={register}
          errors={errors}
        />
      )
    default:
      return <></>
  }
}

export default MasterForm
