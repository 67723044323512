import React from 'react'
import { Alert, Snackbar } from '@mui/material'
import useToast from 'hooks/useToast'
import { CheckCircleOutlined, ErrorOutline } from '@mui/icons-material'

const ALERT_BG = {
  success: '#81C784',
  error: '#E57373',
}

const ALERT_ICON = {
  success: <CheckCircleOutlined />,
  error: <ErrorOutline />,
}

const Toast: React.FC = () => {
  const { hideToast, toastState } = useToast()

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    hideToast()
  }

  return (
    <div>
      <Snackbar
        open={toastState.open}
        autoHideDuration={toastState.duration}
        onClose={handleClose}
        anchorOrigin={
          toastState.direction
            ? toastState.direction
            : { vertical: 'top', horizontal: 'right' }
        }
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={toastState.type}
          icon={ALERT_ICON[toastState.type]}
          style={{
            background: ALERT_BG[toastState.type],
            color: '#000000',
          }}
        >
          {toastState.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Toast
