import GeneratedFormFields, {
  FieldType,
} from 'components/AutoForm/GeneratedFormFields'
import FormCard from '../widgets/FormCard'
import { FormProps } from './type'

const BannerInfo: React.FC<FormProps> = ({
  optional,
  onChange,
  register,
  errors,
  control,
}) => {
  return (
    <FormCard header="Banner Info" optional={optional} onChange={onChange}>
      <>
        <GeneratedFormFields
          register={register}
          errors={errors}
          control={control}
          formFields={[
            {
              type: FieldType.PROJECT,
              title: 'Project Name',
              name: 'projectName',
              isRequired: true,
              useNameAsValue: true,
            },
            {
              type: FieldType.STRING,
              title: 'Sale Title',
              name: 'title',
              isRequired: true,
              placeholder: 'Sale Title',
            },
            {
              type: FieldType.STRING,
              title: 'Alt description',
              name: 'description',
              isRequired: false,
              placeholder: 'Alt description',
            },
          ]}
        />
      </>
    </FormCard>
  )
}

export default BannerInfo
