import { Box, Typography, useTheme } from '@mui/material'

interface Props {
  children: React.ReactNode
}

const ModalHeader: React.FC<Props> = ({ children }) => {
  const theme = useTheme()
  return (
    <Box>
      <Typography color={theme.palette.text.primary} variant="h6">
        {children}
      </Typography>
    </Box>
  )
}

export default ModalHeader
